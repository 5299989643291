<template>
  <div class="s_task_details_yu_sou_time_pop">
    <van-popup v-model="show" class="s-notice-pop-container g-flex-column g-flex-align-center">
      <div class="s-notice-close" @click="confirm">
        <i class="iconfont icon-guanbi"></i>
      </div>
      <div class="s-notice-title">预售提示</div>
      <div class="s-notice-msg">
        {{msg}}
      </div>

       <div class="s-lookfor-btn">
        <operation-button :buttonObj="buttonObj" @clickItem="emitClick"/>
      </div>
    </van-popup>
  </div>
</template>

<script>
  import OperationButton from '@/components/OperationButton.vue'
  // import { ImagePreview } from 'vant';
  // import { addEventListener } from '@/utils/index.js'
  export default {
    components: { OperationButton },
    data() {
      return {
        msg: '',
        show: false,
        buttonObj: {
          claSS: 'c-btn-default c-btn-border-radius',
          title: '确认',
          event: 'confirm'
        }
      }
    },
    methods: {
      onShow(flag, alertMsg = '') {
        this.msg = alertMsg
        this.show = flag
      },
      confirm() {
        this.show = false
      },
      emitClick(event) {
        if(!event) return
        this[event]()
      },
    }
  }
</script>

<style lang="scss">
.s_task_details_yu_sou_time_pop {
  .s-notice-pop-container {
    min-width: 270px;
    max-width: 320px;
    min-height: 300px;
    max-height: 450px;
    padding: 26px 26px 13px 26px;
    border-radius: 12px;
    .s-notice-close {
      position: absolute;
      right: 10px;
      top: 10px;
      padding: 5px;
      .icon-guanbi {
        font-size: 18px;
        color: #B0B0B0;
      }
    }
    .s-notice-title {
      color: $main_color;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 2px;
    }
    .s-notice-msg {
      flex: 1;
      overflow-y: auto;
      padding-top: 14px;
      color: #6F6F6F;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 1px;
      img {
        width: 100%;
        object-fit: cover;
      }
    }

    .s-lookfor-btn {
      align-self: stretch;
      padding: 20px 0px 0px;
    }
  }
}
</style>